<template>
  <div>
    <open-live-account
      v-if="$store.getters.get_profile.state === 'Demo'"
    ></open-live-account>
    <div v-if="$store.getters.get_profile.state === 'Pending'">
      <Header>
        <template v-slot:title>
          <span>
            {{ $t("add-a-new-trading-account") }}
          </span>
        </template>
      </Header>
      <complete-id-identification title=""></complete-id-identification>
    </div>

    <div v-if="$store.getters.get_profile.state === 'Full'">
      <ValidationObserver v-slot="{ handleSubmit, reset }">
        <form @submit.prevent="handleSubmit(addAccount)" @reset.prevent="reset">
          <Header>
            <template v-slot:title>
              <span v-if="whitelabel === 'Blackstone'">
                {{ $t("add-a-new-trading-account") }}
              </span>
              <p class="mt-1 max-w-2xl text-sm font-light text-gray-500">
                <span>
                  {{
                    $t(
                      "choose-your-desired-trading-platform-and-currency-settings"
                    )
                  }}
                </span>
              </p>
            </template>
          </Header>
          <div
            class="bg-white shadow rounded-md min-h-screen max-w-3xl m-auto sm:mt-5"
          >
            <!-- Replace with your content -->
            <div>
              <!--            Message when profile isn't complete v-if="$store.getters.get_profile.state !== 'Full'"-->
              <div class="sm:px-0 pt-8">
                <dl v-if="$store.getters.get_live_backend_accounts.length > 0">
                  <div class="sm:px-8 sm:py-2">
                    <dt class="mb-1 text-sm leading-5 font-normal">
                      {{ $t("select-account") }}
                    </dt>
                    <dd
                      class="text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                      <div class="sm:col-span-2 flex">
                        <button
                          v-for="(account, index) in get_live_backend_accounts"
                          id="account_1"
                          :key="index"
                          :class="
                            selectedAccount === account ? 'border-blue-500' : ''
                          "
                          class="cursor-pointer p-5 border rounded mr-3 w-full focus:outline-none"
                          type="reset"
                          @click="setAccount(account)"
                        >
                          <img
                            v-if="
                              account.backend.name ===
                              'Cloud-SingleCurrencyTrading'
                            "
                            :src="
                              selectedAccount === account
                                ? require(`../../assets/icon-${account.backend.name}-white.png`)
                                : require(`../../assets/icon-${account.backend.name}.png`)
                            "
                            alt="Meta Logo"
                            class="m-auto"
                          />
                          <img
                            v-else
                            :src="
                              selectedAccount === account
                                ? require(`../../assets/${account.backend.type}-white.png`)
                                : require(`../../assets/${account.backend.type}.png`)
                            "
                            alt="Meta Logo"
                            class="m-auto"
                          />
                        </button>
                      </div>
                    </dd>
                  </div>
                  <div class="sm:px-8 mt-5">
                    <dt class="mb-1 text-sm leading-5 font-normal">
                      {{ $t("Currency") }}
                    </dt>
                    <dd
                      class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2"
                    >
                      <div class="sm:col-span-2">
                        <ValidationProvider
                          v-if="selectedAccount.backend"
                          v-slot="{ errors }"
                          name="password_current"
                          rules="required"
                        >
                          <v-select
                            v-model="currency"
                            :options="
                              get_live_backend_accounts.find(
                                (x) => x.backend === selectedAccount.backend
                              ).currencies
                            "
                            :placeholder="
                              $t(
                                'select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11'
                              )
                            "
                            label="symbol"
                          ></v-select>
                          <span class="text-sm text-red-600 h-4">{{
                            errors[0]
                          }}</span>
                        </ValidationProvider>
                      </div>
                    </dd>
                  </div>
                </dl>
              </div>
              <div
                v-if="$store.getters.get_live_backend_accounts.length > 0"
                class="flex-shrink-0 px-8 py-4 space-x-4 flex justify-end"
              >
                <span
                  v-if="$store.getters.get_profile.state === 'Full'"
                  class="inline-flex rounded-md shadow-sm"
                >
                  <Button
                    :disabled="isDisabled"
                    class="relative bg-main-button-color inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-normal rounded-md text-white focus:outline-none focus:shadow-outline-indigo"
                    type="submit"
                  >
                    {{ $t("create-account") }}
                  </Button>
                </span>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import SelectMenu from "@/components/SelectMenu";
import { get_live_backend_accounts } from "@/store/getters";
import { account_data } from "@/store/actions";
import CompleteIdIdentification from "@/components/CompleteIdIdentification";
import Header from "@/components/Header";
import OpenLiveAccount from "@/views/trading-accounts/OpenLiveAccount";

export default {
  name: "AddLiveAccount",
  components: {
    OpenLiveAccount,
    SelectMenu,
    CompleteIdIdentification,
    Header,
  },
  data() {
    return {
      selectedAccount: {},
      currency: "",
      isDisabled: false,
      whitelabel: process.env.VUE_APP_WHITELABEL,
    };
  },
  computed: {
    ...mapGetters(["get_live_backend_accounts", "get_account_data"]),
  },
  created() {
    this.$store.dispatch("get_backends").then(() => {
      if (this.get_live_backend_accounts) {
        this.selectedAccount = this.get_live_backend_accounts[0];
      }
    });
    this.$store.dispatch("user_data");
  },
  methods: {
    addAccount() {
      this.isDisabled = true;
      const data = {
        backend: this.selectedAccount.backend.id,
        currency: this.currency,
        group: null,
      };
      this.$store
        .dispatch("add_account", data)
        .then(() => {
          this.$emit("close");
          this.isDisabled = false;
          this.$store.dispatch("account_data");
          this.$notify({
            group: "foo",
            text: `${this.$t(
              "trading-account-creation-request-sent-to-our-team"
            )}`,
          });
          this.$router.replace("/account-summary");
        })
        .catch((err) => {
          this.isDisabled = false;
          if (Object.values(err.data)[0][0].text) {
            this.$notify({
              group: "foo",
              text: `${Object.values(err.data)[0][0].text}`,
              type: "warn",
            });
          } else if (
            Object.values(err.data)[0][0].startsWith("Object with symbol=")
          ) {
            this.$notify({
              group: "foo",
              text: `${Object.values(err.data)[0][0]}`,
              type: "warn",
            });
          } else {
            this.$notify({
              group: "foo",
              text: `${Object.values(err.data)[0][1].text}`,
              type: "warn",
            });
          }
        });
    },
    setAccount(backend) {
      if (this.selectedAccount !== backend) {
        this.currency = "";
      }
      this.selectedAccount = backend;
    },
  },
};
</script>
