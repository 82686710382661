var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fixed z-10 inset-0 overflow-y-auto"},[_c('div',{staticClass:"flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"},[_vm._m(0),_c('span',{staticClass:"hidden sm:inline-block sm:align-middle sm:h-screen",attrs:{"aria-hidden":"true"}},[_vm._v("​")]),_c('div',{staticClass:"inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6",attrs:{"aria-labelledby":"modal-headline","aria-modal":"true","role":"dialog"}},[_c('div',{staticClass:"mt-3 text-center sm:mt-5"},[_c('div',{staticClass:"mt-2"},[_c('div',{staticClass:"text-sm text-gray-500",domProps:{"innerHTML":_vm._s(_vm.getScore.message)}})]),_c('div',{staticClass:"mt-5 sm:mt-6 flex flex-col items-center justify-between gap-4"},[(_vm.getScore.type === 'warning')?_c('button',{staticClass:"w-full relative inline-flex items-center justify-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50",class:{
              'cursor-not-allowed': _vm.isLoading,
              'opacity-50': _vm.isLoading,
            },attrs:{"disabled":_vm.isLoading,"type":"button"},on:{"click":_vm.next}},[_vm._v(" "+_vm._s(_vm.$t("Close"))+" ")]):_vm._e(),(_vm.getScore.type === 'fail')?_c('button',{staticClass:"w-full relative inline-flex items-center justify-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50",class:{
              'cursor-not-allowed': _vm.isLoading,
              'opacity-50': _vm.isLoading,
            },attrs:{"disabled":_vm.isLoading,"type":"button"},on:{"click":_vm.closeWithNotProceed}},[_vm._v(" I do not wish to proceed ")]):_vm._e(),(_vm.getScore.type === 'fail')?_c('button',{staticClass:"relative w-full bg-main-button-color inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm leading-5 font-normal rounded-md text-white focus:outline-none focus:shadow-outline-indigo",class:{
              'cursor-not-allowed': _vm.isLoading,
              'opacity-50': _vm.isLoading,
            },attrs:{"type":"button","disabled":_vm.isLoading},on:{"click":_vm.next}},[_vm._v(" "+_vm._s(_vm.isLoading ? _vm.$t("Loading") : _vm.$t("I accept the risk and I want to proceed"))+" ")]):_vm._e()])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fixed inset-0 transition-opacity",attrs:{"aria-hidden":"true"}},[_c('div',{staticClass:"absolute inset-0 bg-gray-500 opacity-75"})])
}]

export { render, staticRenderFns }