<template>
  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div aria-hidden="true" class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <span
        aria-hidden="true"
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        >&#8203;</span
      >
      <div
        aria-labelledby="modal-headline"
        aria-modal="true"
        class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
        role="dialog"
      >
        <div class="mt-3 text-center sm:mt-5">
          <div class="mt-2">
            <div class="text-sm text-gray-500" v-html="getScore.message"></div>
          </div>
          <div
            class="mt-5 sm:mt-6 flex flex-col items-center justify-between gap-4"
          >
            <button
              v-if="getScore.type === 'warning'"
              @click="next"
              :class="{
                'cursor-not-allowed': isLoading,
                'opacity-50': isLoading,
              }"
              :disabled="isLoading"
              type="button"
              class="w-full relative inline-flex items-center justify-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
            >
              {{ $t("Close") }}
            </button>

            <button
              v-if="getScore.type === 'fail'"
              @click="closeWithNotProceed"
              :class="{
                'cursor-not-allowed': isLoading,
                'opacity-50': isLoading,
              }"
              :disabled="isLoading"
              type="button"
              class="w-full relative inline-flex items-center justify-center px-4 py-2 border border-gray-300 text-sm leading-5 font-normal rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50"
            >
              I do not wish to proceed
            </button>
            <button
              v-if="getScore.type === 'fail'"
              @click="next"
              type="button"
              :class="{
                'cursor-not-allowed': isLoading,
                'opacity-50': isLoading,
              }"
              :disabled="isLoading"
              class="relative w-full bg-main-button-color inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm leading-5 font-normal rounded-md text-white focus:outline-none focus:shadow-outline-indigo"
            >
              {{
                isLoading
                  ? $t("Loading")
                  : $t("I accept the risk and I want to proceed")
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScoreModal",
  data() {
    return {
      message: "",
      score: 0,
      warningMessage: "",
      isLoading: false,
    };
  },
  props: {
    openDemoModal: {
      type: Function,
      required: true,
    },
  },
  created() {},
  methods: {
    closeWithNotProceed() {
      this.$store.dispatch("set_score_modal", false);
      this.$store.dispatch("reset_step");
      this.openDemoModal();
    },
    next() {
      this.isLoading = true;
      this.$store
        .dispatch("send_signup_data_open_live_account_step_two", {
          is_finished: true,
        })
        .then((res) => {
          this.isDisabled = false;
          this.$store.dispatch("profile");
          if (this.$store.getters.get_suitable.result) {
            this.$store.dispatch("get_backends");
            this.$store.dispatch("set_score_modal", "");
          }
        })
        .catch((err) => {
          this.isDisabled = false;
          this.$notify({
            group: "foo",
            text: err,
            type: "warn",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    getScore() {
      const suitable = this.$store.state.suitable;
      const score = this.$store.getters.get_score_modal;
      return {
        type: score,
        message: suitable.message,
      };
    },
  },
};
</script>
